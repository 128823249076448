import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const CSGuideTrimPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dimension Trimming</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_dimtrim.png"
            alt="Dimension Trimming"
          />
        </div>
        <div className="page-details">
          <h1>Dimension Trimming</h1>
          <h2>A guide for the Dimension Trimming mode.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content">
        <SectionHeader title="Overview" />
        <p>
          Dimension Trimming is the new weekly endgame PVE game mode where you
          need to prepare 3 unique squads to fight against 3 different stages
          depending on the season. Each player is allowed up to 3 hits and up to
          30 retries per week.
        </p>
        <p>
          Currently there are 2 different branches(Jungle and Volcano) where you
          start at level 1, and each clear will progress the branch to the next
          level with increased CP requirement and stronger debuffs.
        </p>
        <SectionHeader title="Requirements" />
        <p>To unlock the mode you must clear Episode 8 of the main story.</p>
        <p>Other requirements:</p>
        <ul>
          <li>
            You must have 3 unique squads which may NOT include duplicate
            characters or operators (unlike Danger Close). Each gear piece may
            also be only used once,
          </li>
          <li>
            Each squad should also meet the Combat Power requirements otherwise
            the squad will get affected by stat penalty,
          </li>
          <li>
            The base CP requirement starts at 140,000 at level 1 and goes up to
            270,000 at level 100,
          </li>
          <li>
            The maximum penalty is at -50% all stats when your squad total CP is
            20% below the requirement.
          </li>
        </ul>
        <SectionHeader title="Rewards" />
        <p>
          Dimension trimming rewards you with a set of new T7 relic gears based
          on the season:
        </p>
        <ul>
          <li>
            Jungle season gears have substats focused on increasing healing,
          </li>
          <li>
            Volcano season gears substats focused on different types of DMG
            increase(Ultimate skill increase, Special skill increase, DEF
            Penetration etc).
          </li>
        </ul>
        <p>Both Gears share the same set option pools.</p>
        <p>List of all set options:</p>
        <ul>
          <li>Debuff RES 10% (2p)</li>
          <li>Barrier Enhancement 20% (2p)</li>
          <li>Incoming Healing +15% (2p)</li>
          <li>Anti-Air DMG RES 10% (2p)</li>
          <li>Anti-Ground DMG RES 10% (2p)</li>
          <li>Melee DMG RES 15% (2p)</li>
          <li>Ranged DMG RES 15% (2p)</li>
          <li>Role ADV DMG INC 30% (4p)</li>
          <li>Role DISADV DMG DEC 15% (2p)</li>
          <li>Def Penetration 20% (4p)</li>
          <li>Healing Enhancement 25% (4p)</li>
          <li>Anti-Air DMG 15% (4p)</li>
          <li>Anti-Ground DMG 15% (4p)</li>
          <li>SPD 15% (4p)</li>
          <li>Special Skill DMG AMP 30% (4p)</li>
          <li>Ultimate Skill DMG AMP 30% (4p)</li>
        </ul>
        <SectionHeader title="Video" />
        <p>Check some examples videos that show how to clear the stages.</p>
        <h5>Jungle</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="YuuwJetUq2w" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="kN33T1ZBJCk" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="BWsGeIlYzrA" />
          </Col>
        </Row>
        <h5>Volcano</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="TBeaxleGcBQ" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="9Va0-xGIabM" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="8BuovCC1fyY" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
    </DashboardLayout>
  );
};

export default CSGuideTrimPage;

export const Head: React.FC = () => (
  <Seo
    title="Dimension Trimming | Counter Side | Prydwen Institute"
    description="A guide for the Dimension Trimming mode."
  />
);
